<template>
<div style="margin-top: 80px">
    <BasePageBreadCrumb :pages="pages" title="novo usuário" :menu="menu"/>
    <div class="container-fluid qsuite-c-fluid-0">
        <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <div class="card card-bg-trans">
                        <div class="card-body pt-4 px-0">
                            <form class="px-2 needs-validation" novalidate @submit.prevent="save">
                                <div class="form-body">
                                    <div class="row">
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>Nome </label>
                                                <input type="text" class="form-control" v-model="user.first_name" v-validate="'required'" name="first_name" :class="{'is-invalid': errors.has('first_name')}">
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>Sobrenome </label>
                                                <input type="text" class="form-control" v-model="user.last_name" v-validate="'required'" name="last_name" :class="{'is-invalid': errors.has('last_name')}">
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>CPF</label>
                                                <input type="text" class="form-control" v-model="user.cpf" v-validate="'required'" v-mask="'###.###.###-##'" name="cpf" :class="{'is-invalid': errors.has('cpf')}">
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>E-mail </label>
                                                <input type="email" class="form-control" placeholder="abc@example.com" v-model="user.email" v-validate="'required|email'" name="email" :class="{'is-invalid': errors.has('email')}">
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>Whatsapp </label>
                                                <input type="tel" class="form-control" placeholder="(99) 9 9999-9999" v-model="user.cellphone" v-validate="'required'" name="cellphone" :class="{'is-invalid': errors.has('cellphone')}" v-mask="['(##) ####-####', '(##) # ####-####']">
                                            </div>
                                        </div>
                                        <div class="col-12"></div>
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>Senha </label>
                                                <input type="password" class="form-control" id="passtext" placeholder="********" v-model="user.password" v-validate="passwordValidate" ref="password" name="password" :class="{'is-invalid': errors.has('password')}">
                                            </div>
                                        </div>
                                        <div class="col-12"></div>
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>Confirmar senha </label>
                                                <input type="password" class="form-control" id="passtext" placeholder="********" v-validate="confirmPasswordValidate" name="confirmed" :class="{'is-invalid': errors.has('confirmed')}">
                                            </div>
                                            <small id="name1" class="badge badge-default badge-info form-text text-white float-left">Deve ter entre 8 e 20 caracteres.</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-actions">
                                    <div class="text-right">
                                        <button type="reset" class="btn btn-dark">Cancelar</button>
                                        <button type="submit" class="btn ml-2 btn-info">{{ id ? 'Atualizar' : 'Cadastrar'}}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import QAdminService from "@/services/resources/QAdminService";
const service = QAdminService.build();

export default {
   data(){
       return{
           user:{
               first_name: null,
               last_name: null,
               cpf: null,
               email: null,
               cellphone: null,
               password: null
           },
           id: null,
           pages:[
                {
                    name: 'dashboard',
                    to: 'Dashboard'
                },
                {
                    name: 'usuários',
                    to: 'User'
                }
            ],
            menu:[
                {
                    name: 'Usuários',
                    to: 'User'
                },
                {
                    name: 'Novo usuário',
                    to: 'UserNew'
                }
            ]
       }
   },
   computed:{
       passwordValidate(){
           return this.id ? 'min:8' : 'required|min:8';
       },
       confirmPasswordValidate(){
           return this.id ? 'confirmed:password' : 'required|confirmed:password';
       },
   },
   methods:{
       save(){
        this.$validator.validateAll().then((result) => {
            if (result) {
                let user = Object.assign(this.user)
                if(this.id){
                    user.id = this.id;

                    service
                    .update(user)
                    .then(resp => {
                    this.$bvToast.toast('Seu usuário foi atualizado com sucesso!', {
                        title: 'Usuário atualizado',
                        autoHideDelay: 5000,
                        type: 'success'
                        })
                    })
                    .catch(err => {
                        this.$bvToast.toast('Este e-mail já foi cadastrado no sistema.', {
                            title: 'Criação do usuário',
                            autoHideDelay: 5000,
                            type: 'danger'
                        })
                    })
                }else{
                    service
                    .create(user)
                    .then(resp => {
                    this.$bvToast.toast('Seu usuário foi criado com sucesso!', {
                        title: 'Síndico criado',
                        autoHideDelay: 5000,
                        type: 'success'
                        })
                    })
                    .catch(err => {
                        this.$bvToast.toast('Este e-mail já foi cadastrado no sistema.', {
                            title: 'Criação do usuário',
                            autoHideDelay: 5000,
                            type: 'danger'
                        })
                    })
                }

          
            }
        });
       },
       fetchUser(){

        if(!this.id){
            return;
        }

        let data = {
            id: this.id
        }

        service
        .read(data)
        .then(resp => {
            this.user = resp;
            this.$validator.reset();
        })
        .catch(err => {
            console.log(err)
        })

       }
   },
   mounted(){
        this.id = this.$route.params.id;
        this.fetchUser();
   }
}
</script>